import Vue from 'vue'
import VueRouter from 'vue-router'
import Timeline from '../views/Timeline'
import Timeline2 from '../views/Timeline2'
import qrcode from '../views/qrcode'
import Index from '../views/index'
import Publicity from '../views/publicity'

const Profile2 = () => import('../views/profile2')


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'Publicity',
        redirect: '/poster',
        component: Publicity
    },
    {
        path: '/index',
        name: 'Index',
        component: Index
    },
    {
        path: '/profile2',
        name: 'profile',
        component: Profile2
    },
    {
        path: '/timeline',
        component: Timeline,
    },
    {
        path: '/timeline2',
        component: Timeline2,
    },
    {
        path: '/qrcode',
        component: qrcode,
    },
    {
        path: '/poster',
        component: () => import('../views/Poster'),
    },
    {
        path: '/task',
        component: () => import('../views/Task'),
    },
    {
        path: '/verify',
        component: () => import('../views/Verify'),
    },
    {
        path: '/tip',
        component: () => import('../views/Tip'),
    },
    {
        path: '/my',
        component: () => import('../views/My'),
    },
    {
        path: '/ranings',
        component: () => import('../views/Ranings'),
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
function _isMobile () {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
router.beforeEach((to, from, next)=> {
    // 当页面访问非poster或tip时，需要在移动端进行访问，否则会自动跳到tip
    if(to.path ==='/poster' || to.path ==='/tip' || _isMobile()){
        next();
    }else{
        next({ path: '/tip' })
    }
})

export default router
