<template>
  <div class="iframe-container">
    <img id="bg-img" src="../assets/bg1.jpg" alt="" />

    <!-- 宣传页面(项目首页) -->
    <!-- <iframe
      name="iframeMap"
      id="iframeMapViewComponent"
      v-bind:src="HtmlPath"
      frameborder="0"
      border="0"
      scrolling="yes"
      ref="iframeDom">
    </iframe> -->

    <div class="menu">
      <div id="evaluation" class="left" @click="goActivity">
        <span><i class="iconfont icon-flow-deterimne"></i>开始测评</span>
      </div>
      <div id="search" class="left" @click="goProfile">
        <span><i class="iconfont icon-chaxun"></i>查询结果</span>
      </div>
      <div id="recommend" @click="goIndex">
        <span><i class="iconfont icon-zhuanfa"></i>推荐好友</span>
      </div>
    </div>
    <img id="hidden-img" loading="lazy" src="../assets/bg.jpg" alt="" />
  </div>
</template>

<script>
import axios from "../api/http";
export default {
  name: "Publicity",
  data () {
    return {
      // HtmlPath: 'https://jinshuju.net/f/UCRDu6',
      user: {},
      openid: "",
    };
  },

  methods: {
    //获取用户个人信息
    async getUserInfo () {
      //判断用户是否第一次进来 第一次进入 正常发送请求 不是第一次 阻止请求发送
      if (localStorage.getItem("user")) {
        if (localStorage.getItem("search")) {
          document.getElementById("search").style.display = "block";
        }
        return;
      }

      let params = {
        code: this.$route.query.code,
        state: this.$route.query.state,
      };
      console.log(params);
      await axios({
        method: "GET",
        url: "/wechat",
        params: params,
      })
        .then((res) => {
          console.log(res);
          this.user = {
            avatar: res.data.user_data.headimgurl,
            username: res.data.user_data.nickname,
            front_qrcode: res.data.forward_qrcode,
            openid: res.data.user_data.openid,
          };
          //将用户信息存储到本地
          localStorage.setItem("user", JSON.stringify(this.user));
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log(this.user);
      await this.getProfileResult();
    },

    //获取用户是否参加过测评
    async getProfileResult () {
      let res = await axios({
        method: "GET",
        url: "/evaluation/getResult",
      });
      console.log(res);
      let openidArr = res.data;
      //遍历数组 得出用户是否参加过测评考试
      let result = openidArr.some((item) => this.user.openid === item);
      console.log(this.user.openid);
      console.log(result);
      if (result) {
        document.getElementById("search").style.display = "block";
        localStorage.setItem("search", true);
      }
    },

    //跳转到活动页
    goActivity () {
      location.href = "https://jinshuju.net/f/XPW7tt";
    },

    //跳转个人能力海报页
    goProfile () {
      this.$router.push("/profile2");
    },

    //跳转到裂变海报页
    goIndex () {
      // let data = {
      //   code: this.$route.query.code,
      //   state: this.$route.query.state,
      // }
      // console.log(data);
      // this.$router.push({ path: '/index', query: { data } })
      this.$router.push("/index");
    },
  },
  created () {
    // await this.getUserInfo()
  },

  mounted () {
    this.getUserInfo();

    // 解决在裂变页返回宣传页时,img遮挡屏幕无法滚动
    if (document.getElementById("poster-Img")) {
      document.getElementById("poster-Img").style.display = "none";
    }
    if (document.getElementById("profile-poster-Img")) {
      document.getElementById("profile-poster-Img").style.display = "none";
    }
  },
};
</script>

<style  scoped>
.iframe-container {
  width: 100%;
  /* height: 19.413rem; */
  height: 100%;
}
#bg-img {
  width: 100%;
  height: 100%;
}

/* iframe盒子 */
/* #iframeMapViewComponent {
  width: 100%;
  height: 100%;
} */

/* 底部按钮 */
.menu {
  width: 100%;
  height: 1.6rem;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px red, /*左边阴影*/ 0px 0px 3px gray,
    /*上边阴影*/ 0px 0px 0px green, /*右边阴影*/ 0px 0px 0px blue; /*下边阴影*/
}
.menu div {
  width: 130px;
  height: 35px;
  line-height: 35px;
}
.menu span {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
}
#evaluation {
  font-size: 24px;
  color: #e05331;
}

#search {
  font-size: 24px;
  color: #e05331;
  display: none;
}

#recommend {
  font-size: 20px;
  /* background-color: #e05331; */
  color: #e05331;
  border-radius: 20px;
}

#recommend .iconfont {
  color: #e05331;
  font-size: 0.533rem;
  margin-right: 0.08rem;
}
.menu span {
  vertical-align: middle;
}

.iconfont {
  color: #e05331;
  font-size: 25px;
  vertical-align: middle;
}

#hidden-img {
  display: none;
}
</style>